import { Outlet } from "react-router-dom";

import { useUser } from "../../contexts/UserContext";

import ResponsiveDrawer from "../ResponsiveDrawer";

const ShowSidebarForAuthUser = () => {
  const { user } = useUser();

  if (user) {
    // Authenticated -> Show sidebar
    return (
      <ResponsiveDrawer>
        <Outlet />
      </ResponsiveDrawer>
    );
  }

  // Unauthenticated -> Hide sidebar
  return <Outlet />;
};

export default ShowSidebarForAuthUser;

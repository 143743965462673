import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  useLocation,
} from "react-router-dom";

import { ADMIN, DEFAULT } from "./userRole";
import { useUser } from "../contexts/UserContext";

import Loader from "../components/Loader";
import ProtectedRoute from "../components/ProtectedRoute";
import HidePublicRouteForAuthUser from "../components/HidePublicRouteForAuthUser";
import ShowSidebarForAuthUser from "../components/ShowSidebarForAuthUser";
import ResponsiveDrawer from "../components/ResponsiveDrawer";

// Public Pages
const Landing = lazy(() => import("../pages/public/Landing"));

// Auth Pages
const Login = lazy(() => import("../pages/public/auth/Login"));
const Register = lazy(() => import("../pages/public/auth/Register"));
const Verify = lazy(() => import("../pages/public/auth/Verify"));
const VerifySuccess = lazy(() => import("../pages/public/auth/VerifySuccess"));
const FetchProfileByToken = lazy(() =>
  import("../pages/public/auth/FetchProfileByToken")
);

// Error Pages
const NotFound = lazy(() => import("../pages/error/NotFound"));
const Unauthorized = lazy(() => import("../pages/error/Unauthorized"));

// Private Pages
const Dashboard = lazy(() => import("../pages/private/Dashboard"));
const ViewRooms = lazy(() => import("../pages/private/rooms/ViewRooms"));
const PreviewRoom = lazy(() => import("../pages/private/rooms/PreviewRoom"));
const ViewUsers = lazy(() => import("../pages/private/users/ViewUsers"));
const ViewUsersReferralList = lazy(() =>
  import("../pages/private/users/UsersReferralList")
);
const ViewUsersWallets = lazy(() =>
  import("../pages/private/users/UsersWallets")
);
const UserDeposit = lazy(() => import("../pages/private/users/UserDeposit"));
const UserWithdraw = lazy(() => import("../pages/private/users/UserWithdraw"));
const UserTransfers = lazy(() =>
  import("../pages/private/users/UserTransfers")
);
const Notifications = lazy(() => import("../pages/private/Notifications"));
const ViewTournaments = lazy(() =>
  import("../pages/private/tournaments/ViewTournaments")
);

const allowedRoutes = [
  "/dashboard",
  "/rooms",
  "/rooms/:roomCode",
  "/users",
  "/users/referral-list",
  "/users/wallets",
  "/users/wallets/deposit",
  "/users/wallets/withdraw",
  "/users/wallets/transfers",
  "/tournaments",
  "/notifications",
];

const Root = () => {
  const { user } = useUser();
  const location = useLocation();

  if (user) {
    if (allowedRoutes.some((route) => location.pathname.startsWith(route))) {
      return (
        <ResponsiveDrawer>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </ResponsiveDrawer>
      );
    } else {
      return (
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      );
    }
  }

  return (
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      {/* Public Pages */}
      <Route index element={<Landing />} />

      {/* Auth Pages */}
      <Route path="/login" element={<HidePublicRouteForAuthUser />}>
        <Route index element={<Login />} />
      </Route>

      <Route path="/register" element={<HidePublicRouteForAuthUser />}>
        <Route index element={<Register />} />

        <Route path="/register/:referrer" element={<Register />} />
      </Route>

      <Route
        path="/verify-registration"
        element={<HidePublicRouteForAuthUser />}
      >
        <Route index element={<Verify />} />
      </Route>

      <Route path="/verified" element={<HidePublicRouteForAuthUser />}>
        <Route index element={<VerifySuccess />} />
      </Route>

      <Route path="/auth" element={<HidePublicRouteForAuthUser />}>
        <Route index element={<NotFound />} />

        <Route
          path="/auth/:action/:userToken/:ticket"
          element={<FetchProfileByToken />}
        />
      </Route>

      {/* Private Pages */}
      <Route path="/dashboard" element={<ProtectedRoute isRestricted />}>
        <Route index element={<Dashboard />} />
      </Route>

      <Route path="/rooms" element={<ProtectedRoute isRestricted />}>
        <Route index element={<ViewRooms />} />
      </Route>

      <Route path="/rooms/:roomCode" element={<ProtectedRoute isRestricted />}>
        <Route index element={<PreviewRoom />} />
      </Route>

      <Route path="/users" element={<ProtectedRoute isRestricted />}>
        <Route index element={<ViewUsers />} />
      </Route>

      <Route
        path="/users/referral-list"
        element={<ProtectedRoute isRestricted />}
      >
        <Route index element={<ViewUsersReferralList />} />
      </Route>

      <Route path="/users/wallets" element={<ProtectedRoute isRestricted />}>
        <Route index element={<ViewUsersWallets />} />
      </Route>

      <Route
        path="/users/wallets/deposit"
        element={<ProtectedRoute isRestricted scopes={[ADMIN, DEFAULT]} />}
      >
        <Route index element={<UserDeposit />} />
      </Route>

      <Route
        path="/users/wallets/withdraw"
        element={<ProtectedRoute isRestricted scopes={[ADMIN, DEFAULT]} />}
      >
        <Route index element={<UserWithdraw />} />
      </Route>

      <Route
        path="/users/wallets/transfers"
        element={<ProtectedRoute isRestricted scopes={[ADMIN, DEFAULT]} />}
      >
        <Route index element={<UserTransfers />} />
      </Route>

      <Route path="/tournaments" element={<ProtectedRoute isRestricted />}>
        <Route index element={<ViewTournaments />} />
      </Route>

      <Route path="/notifications" element={<ProtectedRoute isRestricted />}>
        <Route index element={<Notifications />} />
      </Route>

      {/* Error Pages */}
      <Route path="/unauthorized" element={<ShowSidebarForAuthUser />}>
        <Route index element={<Unauthorized />} />
      </Route>

      <Route path="/*" element={<ShowSidebarForAuthUser />}>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
);

export default router;

import { createTheme } from "@mui/material/styles";

export const PRIMARY = "#F0BA0A";
export const SECONDARY = "#222222";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY,
    },
    secondary: {
      main: SECONDARY,
    },
  },
});

export default muiTheme;

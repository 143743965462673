import axiosInstance from "../config/axiosInstance";
import { toast } from "react-toastify";

import { ADMIN } from "../config/userRole";

export const login = async (username, password) => {
  try {
    const response = await axiosInstance.post("/user/login", {
      username,
      password,
    });

    let user = {};

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "warning" });

      return null;
    }

    if (response.data && response.data.success) {
      if (response.data.success.data.user) {
        if (response.data.success.data.user.scopes) {
          let scopes = response.data.success.data.user.scopes;

          if (scopes.includes(ADMIN)) {
            user.isAdmin = true;
          } else {
            user.isAdmin = false;
          }

          user.scopes = scopes;
        }

        user.username = response.data.success.data.user.username;
        user.email = response.data.success.data.user.email;
      }

      if (response.data.success.data.ticket) {
        user.ticket = response.data.success.data.ticket;
      }

      if (response.data.success.data.access_token) {
        user.accessToken = response.data.success.data.access_token;
      }

      if (response.data.success.data.username) {
        user.username = response.data.success.data.username;
      }
    }

    return user;
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const register = async (username, email, password, referrer = "") => {
  try {
    const response = await axiosInstance.post("/user/register", {
      username,
      email,
      password,
      referrer,
    });

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      return null;
    }

    if (response.data && response.data.success) {
      toast(response.data.success.message, { type: "success" });
    }

    return { isRegistered: true };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const verify = async (username, code = "") => {
  try {
    const response = await axiosInstance.post("/user/verify", {
      username,
      code,
    });

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      return null;
    }

    if (response.data && response.data.success) {
      toast(response.data.success.message, { type: "success" });
    }

    return { isVerified: true };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const logout = async () => {
  try {
    const response = await axiosInstance.post("/user/logout");

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("wawUser") || "{}");

  if (user && user.accessToken) {
    return user.accessToken;
  }

  return null;
};

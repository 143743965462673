import dayjs from "dayjs";

export const testEmailValidation = (email) => {
  const emailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(email);
};

export const testInputValidation = (input, inputValue) => {
  let isValid = true;
  let errorMessage = "";

  if (
    input?.type !== "number" &&
    input?.isRequired &&
    inputValue.trim().length === 0
  ) {
    isValid = false;
    errorMessage = "required";
  } else if (input?.type === "number" && input.value < 1) {
    isValid = false;
    errorMessage = "minNumberRequired";
  } else if (input?.type === "email" && !testEmailValidation(inputValue)) {
    isValid = false;
    errorMessage = "invalidEmail";
  }

  return {
    isValid: isValid,
    errorMessage: errorMessage,
  };
};

export const testFormValidation = (inputs) => {
  let isFormValid = true;

  for (const entry of Object.values(inputs)) {
    let inputValidation = testInputValidation(entry, entry.value);

    if (!inputValidation.isValid) {
      isFormValid = false;

      break;
    }
  }

  return isFormValid;
};

export const formatDateAsYYYYMMDD = (dayjsDate = null) => {
  if (dayjsDate === null) return null;

  if (dayjs(dayjsDate).isValid()) {
    const dateString = dayjsDate.add(1, "day").toISOString();

    const dateObj = new Date(dateString);
    const day = String(dateObj.getUTCDate()).padStart(2, "0");
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getUTCFullYear());

    return `${year}${month}${day}`;
  } else {
    console.error(
      "Invalid date passed to `formatDateAsDDMMYYYY` helper function"
    );

    return null;
  }
};

export const hasCommonScope = (routerScopes, userScopes) => {
  for (const scope of userScopes) {
    if (routerScopes.includes(scope)) {
      return true;
    }
  }

  return false;
};

export const copyToClipboard = (text) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;

  // Make the textarea non-editable to avoid flicker effect
  textarea.setAttribute("readonly", "");

  // Hide the textarea from view
  textarea.style.position = "absolute";
  textarea.style.left = "-9999px";

  document.body.appendChild(textarea);

  // Select and copy the text
  textarea.select();
  document.execCommand("copy");

  document.body.removeChild(textarea);
};

import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";

import App from "./App";
import muiTheme from "./config/muiTheme";

import { UserProvider } from "./contexts/UserContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={muiTheme}>
    <UserProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ToastContainer position="bottom-right" />

        <App />
      </LocalizationProvider>
    </UserProvider>
  </ThemeProvider>
);

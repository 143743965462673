import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = (location = "public") => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: location === "public" && "100vh",
      }}
    >
      <CircularProgress size={40} />
    </Box>
  );
};

export default Loader;

import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { ADMIN, DEFAULT } from "../../config/userRole";
import { hasCommonScope } from "../../helpers";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import WalletIcon from "@mui/icons-material/Wallet";
import SportsIcon from "@mui/icons-material/Sports";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

import { useUser } from "../../contexts/UserContext";
import { PRIMARY, SECONDARY } from "../../config/muiTheme";

import LogoutButton from "../api/LogoutButton";

import logo from "../../assets/images/logo.svg";

const drawerWidth = 240;

const navigationList = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    link: "/dashboard",
    hasDivider: false,
    scopes: [ADMIN],
  },
  {
    name: "Rooms",
    icon: <MeetingRoomIcon />,
    link: "/rooms",
    hasDivider: true,
    scopes: [ADMIN],
  },
  {
    name: "Users",
    icon: <PersonIcon />,
    link: "/users",
    hasDivider: false,
    scopes: [ADMIN],
  },
  {
    name: "Users Referral",
    icon: <PeopleIcon />,
    link: "/users/referral-list",
    hasDivider: false,
    scopes: [ADMIN],
  },
  {
    name: "Users Wallets",
    icon: <WalletIcon />,
    link: "/users/wallets",
    hasDivider: true,
    scopes: [ADMIN],
  },
  {
    name: "Deposit",
    icon: <ArrowForwardIcon />,
    link: "/users/wallets/deposit",
    hasDivider: false,
    scopes: [ADMIN, DEFAULT],
  },
  {
    name: "Withdraw",
    icon: <ArrowBackIcon />,
    link: "/users/wallets/withdraw",
    hasDivider: false,
    scopes: [ADMIN, DEFAULT],
  },
  {
    name: "Transfers",
    icon: <SwapHorizIcon />,
    link: "/users/wallets/transfers",
    hasDivider: true,
    scopes: [ADMIN, DEFAULT],
  },
  {
    name: "Tournaments",
    icon: <SportsIcon />,
    link: "/tournaments",
    hasDivider: false,
    scopes: [ADMIN],
  },
  {
    name: "Notifications",
    icon: <NotificationsIcon />,
    link: "/notifications",
    hasDivider: false,
    scopes: [ADMIN],
  },
];

const ResponsiveDrawer = (props) => {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useUser();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component="img"
          width="auto"
          height="64px"
          alt="Computer login"
          src={logo}
        />
      </Toolbar>

      <Divider sx={{ background: PRIMARY }} />

      <List>
        {navigationList.map((element, index) => {
          return hasCommonScope(element.scopes, user.scopes) ? (
            <Fragment key={index}>
              <ListItem
                disablePadding
                onClick={() => {
                  setMobileOpen(false);

                  setTimeout(() => {
                    navigate(element.link);
                  }, 50);
                }}
                sx={{ color: PRIMARY }}
              >
                <ListItemButton>
                  <ListItemIcon sx={{ color: PRIMARY }}>
                    {element.icon}
                  </ListItemIcon>

                  <ListItemText primary={element.name} />
                </ListItemButton>
              </ListItem>

              {element.hasDivider && (
                <Divider
                  sx={{
                    background: PRIMARY,
                    my: 1,
                  }}
                />
              )}
            </Fragment>
          ) : null;
        })}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 12px",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            component="p"
            variant="h6"
            noWrap
            sx={{ textTransform: "capitalize" }}
          >
            {user.username}
          </Typography>

          <LogoutButton />
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          zIndex: (theme) => theme.zIndex.drawer - 101,
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: SECONDARY,
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: SECONDARY,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          pt: 10,
          pb: 6,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: "100vh",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default ResponsiveDrawer;

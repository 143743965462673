import { Navigate, Outlet } from "react-router-dom";

import { useUser } from "../../contexts/UserContext";
import { hasCommonScope } from "../../helpers";
import { ADMIN } from "../../config/userRole";

const ProtectedRoute = ({ isRestricted = false, scopes = [ADMIN] }) => {
  const { user } = useUser();

  if (!user) {
    // Not authenticated -> Render login layout
    return <Navigate to="/login" />;
  }

  if (isRestricted) {
    // Component is restricted to a specific role
    if (hasCommonScope(scopes, user.scopes)) {
      // Role is authorized -> Render the component
      return <Outlet />;
    } else {
      // Role is unauthorized -> Render unauthorized layout
      return <Navigate to="/unauthorized" />;
    }
  }

  // Authenticated and not restricted -> Render the component
  return <Outlet />;
};

export default ProtectedRoute;

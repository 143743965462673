import { Navigate, Outlet } from "react-router-dom";

import { useUser } from "../../contexts/UserContext";

const HidePublicRouteForAuthUser = () => {
  const { user } = useUser();

  if (user) {
    if (user.action) {
      switch (user.action) {
        case "withdraw":
          return <Navigate to="/users/wallets/withdraw" />;
        case "deposit":
          return <Navigate to="/users/wallets/deposit" />;
        default:
          break;
      }
    }
    // Authenticated
    if (user.isAdmin) {
      return <Navigate to="/dashboard" />;
    } else {
      return <Navigate to="/users/wallets/deposit" />;
    }
  }

  // Unauthenticated -> Render the component
  return <Outlet />;
};

export default HidePublicRouteForAuthUser;
